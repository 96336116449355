/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick/lib';
import { StaticQuery, graphql } from 'gatsby';
import { NextArrow, PrevArrow } from './Arrows';
import Helmet from './Helmet';
import Layout from './GatsbyLayout';
import TechCard from './TechCard';
import CaseStudyBanner from './CaseStudyBanner';
import Result from './Result';
import Testimonial from './Testimonial';
import ShowMore from './ShowMoreText';

class CaseStudyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: null,
      slideIndex: 1
    };

    this.renderModal = this.renderModal.bind(this);
  }

  componentDidMount() {
    document.querySelectorAll('.slick-arrow img').forEach((arrow) => {
      arrow.setAttribute('tabIndex', '0');
      arrow.onkeypress = () => { arrow.click(); }; // eslint-disable-line no-param-reassign
    });

    if (document.querySelector('.testimonial')) {
      document.querySelector('.testimonial--quote').setAttribute('tabIndex', '0');
      document.querySelector('.testimonial--author').setAttribute('tabIndex', '0');
      document.querySelector('.testimonial--position').setAttribute('tabIndex', '0');
    }
  }

  renderModal = () => {
    const { showModal, imgIndex } = this.state;
    const zoomSettings = {
      focusOnSelect: true,
      className: 'center',
      centerMode: true,
      beforeChange: (current, next) => this.setState({ slideIndex: next + 1 }),
      dots: false,
      infinite: true,
      lazyLoad: 'progressive',
      centerPadding: '450px',
      speed: 500,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,

      responsive: [
        {
          breakpoint: 3000,
          settings: {
            centerPadding: '750px'
          }
        },
        {
          breakpoint: 2500,
          settings: {
            centerPadding: '650px'
          }
        },
        {
          breakpoint: 2000,
          settings: {
            centerPadding: '500px'
          }
        },
        {
          breakpoint: 1600,
          settings: {
            centerPadding: '400px'
          }
        },
        {
          breakpoint: 1450,
          settings: {
            centerPadding: '400px'
          }
        },
        {
          breakpoint: 1250,
          settings: {
            centerPadding: '300px'
          }
        },
        {
          breakpoint: 1100,
          settings: {
            centerPadding: '250px'
          }
        },
        {
          breakpoint: 1000,
          settings: {
            centerPadding: '200px'
          }
        },
        {
          breakpoint: 900,
          settings: {
            centerPadding: '150px'
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 700,
          settings: {
            centerPadding: '30px'
          }
        }
      ]
    };

    return (
      <div className="full-slider">
        <div className="full-slider__closeBtn">
          <button className="fa fa-times-circle fa-2x" onClick={this.handleCloseModal} />
        </div>
        <Slider {...zoomSettings} initialSlide={imgIndex}>
          {Array.isArray(showModal) && showModal.map((image, index) => (
            <div key={image.large}>
              <span>
                <img className="image fit" src={image.large} alt={`slider-${index}`} title={`slider-${index}`} />
              </span>
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  handleCloseModal = () => {
    this.setState({
      showModal: null
    });
  };

  renderBanner = () => {
    const { path, title, shortDescription } = this.props;

    return (
      <div className="main__inner">
        <StaticQuery
          query={graphql`
            query {
              images: allFile {
                edges {
                  node {
                    relativePath
                    name
                    childImageSharp {
                      fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            const image = data.images.edges.find(n => n.node.relativePath.includes(path));
            const fluidSrc = image?.node?.childImageSharp?.fluid;

            return (
              <CaseStudyBanner
                category="Case study"
                title={title}
                description={shortDescription}
                fluid={fluidSrc}
                buttonText="Get a quote"
                onButtonPress="/contact/"
              />
            );
          }}
        />
      </div>
    );
  };

  handleClickImage = (images, imgIndex) => {
    this.setState({
      imgIndex,
      showModal: images
    });
  };

  renderContent = () => {
    const { challenges, description, technologies, services, results } = this.props;

    return (
      <div className="case-study-content">
        {results
          && (
            <div className="case-study-content--results">
              <h2 className="case-study-content--results__title" tabIndex="0">
                {' '}
                Key results
                <span>.</span>
              </h2>
              <div className="case-study-content--results__content">
                { Array.isArray(results) && results.map((result, index) => (
                  <Result
                    key={`result__${index}`} // eslint-disable-line
                    percentage={result.percentage}
                    text={result.text}
                  />
                ))}
              </div>
            </div>
          )
        }
        <div className="case-study-content--section-wrapper">
          <div className="case-study-content--section main__inner main">
            <div className="case-study-content--section--item">
              <h2 className="case-study-content--section--item__title font-title font-weight-bold" tabIndex="0">
                Description
              </h2>
              <div className="case-study-content--section--item__content font-body1" tabIndex="0">
                <ShowMore textTruncate={description[0]} />
              </div>
            </div>
            <div className="case-study-content--section--item">
              <h2 className="case-study-content--section--item__title font-title font-weight-bold" tabIndex="0">
                Challenge
              </h2>
              <div className="case-study-content--section--item__content font-body1" tabIndex="0">
                <ShowMore textTruncate={challenges[0].challenge} />
              </div>
            </div>
            <div className="case-study-content--section--item">
              <h2 className="case-study-content--section--item__title font-title font-weight-bold" tabIndex="0">
                Solution
              </h2>
              <div className="case-study-content--section--item__content font-body1" tabIndex="0">
                <ShowMore textTruncate={challenges[0].solution} />
              </div>
            </div>
          </div>
        </div>
        <div className="case-study-content--items">
          {services
                && (
                <div className="case-study-content--item">
                  <h2 className="case-study-content--item__title font-title font-weight-bold" tabIndex="0">
                    Services provided
                  </h2>
                  <div className="case-study-content--item__content tech-cards">
                    {Array.isArray(services) && services.map(service => (
                      <TechCard key={service} text={service} />
                    ))}
                  </div>
                </div>
                )
            }
          <div className="case-study-content--item">
            <h2 className="case-study-content--item__title font-title font-weight-bold" tabIndex="0">
              Technology stack
            </h2>
            <div className="case-study-content--item__content tech-cards">
              {Array.isArray(technologies) && technologies.map(technology => (
                <TechCard key={technology} text={technology} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      location,
      title,
      shortDescription,
      images,
      technologies,
      thumbnail,
      testimonial,
      linkId,
      video
    } = this.props;
    const { showModal, slideIndex } = this.state;
    const totalSlides = images ? images.length : 0;
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '50px',
      slidesToShow: 3,
      speed: 500,
      adaptiveHeight: false,
      beforeChange: (current, next) => this.setState({ slideIndex: next + 1 }),
      dots: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      lazyLoad: 'progressive',

      responsive: [
        {
          breakpoint: 480,
          settings: {
            touchMove: true,
            beforeChange: (current, next) => this.setState({ slideIndex: next + 1 }),
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: '74px',
            verticalSwiping: false,
            horizontalSwiping: true,
            touchThreshold: 10,
          }
        }
      ]
    };

    const clickPrev = () => {
      document.querySelector('.slick-slider .slick-prev').click();
    };
    const clickNext = () => {
      document.querySelector('.slick-slider .slick-next').click();
    };

    return (
      <Layout location={location}>
        <Helmet
          title={`${title} - Our Portfolio | MCRO`}
          description={shortDescription}
          keywords={technologies.join(', ')}
          thumbnail={thumbnail}
          canonical
          canonicalPage={`our-work/${linkId}`}
        />

        <div className="main resources-banner">
          {this.renderBanner()}
        </div>
        <div className="main">
          <div className="main__inner">
            <div className="main__section">
              {video && (
                <div className="case-study-content--video">
                  <iframe
                    style={{ border: 0 }}
                    width="100%"
                    height="600px"
                    src={video}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              )}
              {this.renderContent()}
              {Array.isArray(images)
              && (
                <div className="slider-layout">
                  <Slider {...settings}>
                    {Array.isArray(images) && images.map((img, imgIdx) => (
                      <div key={img.small}>
                        <span>
                          <img
                            src={img.small}
                            alt={`slider-${imgIdx}`}
                            title={`slider-${imgIdx}`}
                            onClick={() => this.handleClickImage(images, imgIdx)}
                          />
                        </span>
                      </div>
                    ))}
                  </Slider>
                  {totalSlides > 3 && (
                    <div className="slider-section">
                      <PrevArrow onClick={clickPrev} className="slider-arrow" />
                      <div className="slider-layout--index">
                        {slideIndex}
                        /
                        {totalSlides}
                      </div>
                      <NextArrow onClick={clickNext} className="slider-arrow" />
                    </div>
                  )}
                </div>
              )
              }
              {testimonial && (
              <Testimonial
                quote={testimonial.quote}
                imgSrc={testimonial.imageSrc}
                author={testimonial.author}
                position={testimonial.position}
              />
              )}
              {showModal && this.renderModal()}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

CaseStudyDetails.defaultProps = {
  services: null,
  testimonial: null,
  results: null
};

CaseStudyDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  challenges: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  shortDescription: PropTypes.string.isRequired, // eslint-disable-line react/forbid-prop-types
  description: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  results: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  technologies: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  services: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  video: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  testimonial: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default CaseStudyDetails;
