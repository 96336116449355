import React from 'react';
import PropTypes from 'prop-types';

const TechCard = ({ text }) => (
  <div className="tech-cards__item font-weight-light-bold" tabIndex="0">{text}</div>
);

TechCard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TechCard;
