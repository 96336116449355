import React from 'react';
import PropTypes from 'prop-types';
import CaseStudyDetails from '../components/case-study-details';
import projectData from '../data/our-work-data';

const CaseStudy = ({ location }) => {
  const project = projectData.find(item => `/our-work/${item.linkId}/` === location.pathname);
  const path = typeof window !== 'undefined' && window.innerWidth <= 760
    ? `ourWork/${project.linkId}/banner/banner-mobile.jpg`
    : `ourWork/${project.linkId}/banner/banner.jpg`;

  return (
    <CaseStudyDetails
      location={location}
      title={project.title}
      linkId={project.linkId}
      shortDescription={project.shortDescription}
      description={[project.description]}
      challenges={project.challenges ? project.challenges : []}
      id={project.id}
      technologies={project.technologies}
      services={project.services}
      results={project.results}
      testimonial={project.testimonial}
      images={project.images}
      path={path}
      thumbnail={project.cardImage}
      video={project.video}
    />
  );
};

CaseStudy.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

CaseStudy.defaultProps = {
  data: null
};

export default CaseStudy;
