import React, { Component } from 'react';
import TruncateMarkup from 'react-truncate-markup';

class ShowMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldTruncate: true
    };
    this.toggleLines = this.toggleLines.bind(this);
  }

  toggleLines(event) {
    event.preventDefault();
    this.setState(prevState => ({
      shouldTruncate: !prevState.shouldTruncate
    }));
  }


  render() {
    const { textTruncate } = this.props;
    const { shouldTruncate } = this.state;
    const { children } = textTruncate.props;
    return (
      <div className="case-study-truncate">
        { shouldTruncate ? (
          <TruncateMarkup
            lines={10}
            ellipsis={(
              <span>
                ...
                <span>
                  <button className="case-study-truncate--button" onClick={this.toggleLines}> read more</button>
                </span>
              </span>
                )}
          >
            <div>
              {children}
            </div>
          </TruncateMarkup>
        ) : (
          <div>
            <div>
              {children}
            </div>
            <span>
              <button className="case-study-truncate--button" onClick={this.toggleLines}> show less</button>
            </span>
          </div>
        )}
      </div>
    );
  }
}
export default ShowMore;
