import React from 'react';

const Result = (props) => {
  const { percentage, text } = props;
  return (
    <div className="result" tabIndex="0">
      <div className="result__percentage">{percentage}</div>
      <div className="result__text">{text}</div>
    </div>
  );
};
export default Result;
